import React, {useEffect, useState} from 'react';
import '../sass/style.scss';
import {fetchPayerData} from '../redux/payment-details-reducer.js';
import {PROJECT} from '../constants.js';
import Home from './components/home/home.jsx';
import Layout from './components/layout/layout.jsx';
import ConfirmationScreen from './screens/ConfirmationScreen.jsx';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Loader from './components/loader/Loader';
// Const { REACT_APP_ROUTE_BASENAME = "" } = process.env;

/**
 *
 */
function App() {
	const [loading, setLoading] = useState(true);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchPayerData())
			.then(() => setLoading(false))
			.catch(error => {
				setLoading(false);
				alert('קרתה שגיאה בשרת. נא לפנות לשירות הלקוחות של גמא.');
			});
	}, []);

	return (
		<div className='App'>
			<Layout>
				{loading ? (
					<Loader/>
				) : (
					<BrowserRouter basename={PROJECT.APP.BASENAME}>
						<Switch>
							<Route
								exact path='/'
								render={() => <Home/>}/>

							<Route
								path='/success'
								render={() => <ConfirmationScreen/>}/>
						</Switch>
					</BrowserRouter>
				)}
			</Layout>
		</div>
	);
}

export default App;
