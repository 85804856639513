import parsePhoneNumber from 'libphonenumber-js';

const EMPTY_STRING = '';

export default number => {
	if (!number) {
		return EMPTY_STRING;
	}

	number = parsePhoneNumber(number, 'IL');
	number = number.nationalNumber.padStart(10, '0');

	const [prefix = '', phone = ''] = [number.slice(0, 3), number.slice(3)];
	return [prefix, phone].join('-');
};
