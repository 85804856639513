/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-undef */
/* eslint-disable complexity */

import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Button} from 'react-bootstrap';
import {
	changePaymentOption, ccCreateTransaction, payReqCancel, setOpenReqDataAC, changeNumOfPaymentsAC,
} from '../../../redux/payment-details-reducer.js';
import Error from '../error/error.jsx';
import Field from '../Field.js';
import Popup from '../popup/Popup.js';
import PaymentButton from '../PaymentButton.js';
import chevron from '../../../images/chevron.svg';
import numberWithCommas from '../../../utils/numberWithCommas.js';
import fillSelectOptions from '../../../utils/fillSelectOptions.js';
import bit from '../../../images/bit.png';
import paymentsMenu from '../../../images/credit_card_icon.png';

const prefixes = [
	'050',
	'051',
	'052',
	'053',
	'054',
	'055',
	'056',
	'057',
	'058',
];

/**
 *
 */
function Home() {
	const {
		payerName,
		payerLastName,
		paymentFor,
		paymentAmount,
		orderId,
		sender,
		sessionId,
		isFetching,
		numOfPayments,
		errorsHome,
		Ptoken,
		confirmationNumber,
		deleted,
		paymentMethods,
		WEB_PCI_BASE_URL,
		WEB_BIT_JS_BUNDLE,
		isOpenSum,
		isOpenContact,
	} = useSelector(({paymentDetails}) => paymentDetails);
	const [isOpenRequest, setIsOpenRequest] = useState(null);
	const [paymentMethod, setPaymentMethod] = useState(null);

	const dispatch = useDispatch();
	const [cardMethodChozen, setCardMethodChozen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [showModal, setShowModal] = useState(false);

	const {register, watch, formState: {errors, isValid}, setValue, setError, trigger, clearErrors} = useForm();

	useEffect(() => {
		setIsOpenRequest(isOpenSum || isOpenContact);
	}, [isOpenContact, isOpenSum]);

	useEffect(() => {
		if (isOpenSum) {
			register('sum', {required: true, validate: v => Number(v.replaceAll(',', '')) <= 10_000_000});
		}

		if (isOpenContact) {
			register('name', {required: true});
			register('lastName', {required: false});
			register('phone', {required: true, minLength: 7, maxLength: 7});
			register('prefix', {required: true});
		}

		if ((numOfPayments && numOfPayments > 1) || (isOpenSum && !numOfPayments)) {
			register('numOfPayments', {required: false});
		}
	}, [register, isOpenContact, numOfPayments, isOpenSum]);

	useEffect(() => {
		setValue('numOfPayments', '1');
	},[]);

	useEffect(() => {
		if (WEB_BIT_JS_BUNDLE) {
			const script = document.createElement('script');
			script.src = WEB_BIT_JS_BUNDLE;
			script.async = true;
			document.body.append(script);
		}
	}, [WEB_BIT_JS_BUNDLE]);

	useEffect(() => {
		if (sessionId) {
			gamapayInit(sessionId);
		}
	}, [sessionId]);

	useEffect(() => {
		const pciOnMessage = ({data}) => {
			if (data === 'stepBack') {
				setCardMethodChozen(false);
			} else {
				// Successful payment.
				dispatch(ccCreateTransaction(data));
			}
		};

		if (window.addEventListener) {
			window.addEventListener('message', pciOnMessage, false);
		} else if (window.attachEvent) {
			window.attachEvent('onmessage', pciOnMessage, false);
		}
	}, [dispatch]);

	const payerDetails = {
		payerName: isOpenContact ? watch().name : payerName,
		lastName: isOpenContact ? watch().lastName : payerLastName,
		paymentFor,
		paymentAmount: isOpenSum ? watch().sum?.replace(/,/g, '') : paymentAmount,
		phonePrefix: watch().prefix ?? '',
		phone: watch().phone ?? '',
		email: watch().email ?? '',
		orderId,
		numOfPayments: watch().numOfPayments,
	};

	const _cancelRequest = () => {
		// eslint-disable-next-line no-alert
		if (!window.confirm('האם לבטל את בקשת התשלום?')) {
			return;
		}

		dispatch(payReqCancel());
	};

	const fieldsValidation = () => {
		let errors = 0;

		const array = [];
		if (isOpenContact) {
			array.push('name', 'phone', 'prefix');
		}

		if (isOpenSum) {
			array.push('sum');
		}

		if (isOpenSum) {
			const sumWithoutCommas = watch().sum.replaceAll(',', '');
			const numberOfPayments = watch().numOfPayments;
			if (Boolean(sumWithoutCommas) && Number(sumWithoutCommas) < 1) {
				setError('sum', {message: 'הסכום לתשלום חייב להיות גדול או שווה ל-1'});
				errors++;
			} else if (Boolean(sumWithoutCommas) && Number.isNaN(sumWithoutCommas)) {
				setError('sum', {message: 'הסכום לתשלום חייב להיות גדול או שווה ל-1'});
				errors++;
			} else if (Boolean(sumWithoutCommas) && Number(sumWithoutCommas) > 10_000_000) {
				setError('sum', {message: 'לא ניתן להזין סכום גבוה מ- 10,000,000'});
				errors++;
			}

			if (numberOfPayments) {
				const MIN_SINGLE_PAYMENT = 1;
				const singlePaymentSum = Number(sumWithoutCommas) / Number(numberOfPayments);
				if (singlePaymentSum < MIN_SINGLE_PAYMENT) {
					setError('numOfPayments', {message: `עבור עסקת תשלומים גובה כל תשלום לא יקטן מ ${MIN_SINGLE_PAYMENT} ש"ח`});
					errors++;
				}
			}
		}

		const emptyFieldsLength = array.filter(element => {
			if (!watch()[element]) {
				setError(element, {});
				return element;
			}

			return false;
		});
		return errors + emptyFieldsLength.length;
	};

	const nextFunc = () => {
		if (fieldsValidation() > 0) {
			return false;
		}

		if (paymentMethod === 'bit') {
			submitBit();
		} else if (paymentMethod === 'card') {
			submitCC();
		}
	};

	const submitBit = () => {
		switch (isOpenRequest) {
			case true: {
				setShowModal(false);
				setErrorMessage('');
				clearErrors();

				dispatch(setOpenReqDataAC(payerDetails));
				dispatch(changePaymentOption('bit', payerDetails, true));
				dispatch(changeNumOfPaymentsAC(watch().numOfPayments));
				break;
			}

			default: {
				setShowModal(false);
				dispatch(changePaymentOption('bit', payerDetails));
				dispatch(changeNumOfPaymentsAC(watch().numOfPayments));
			}
		}
	};

	const submitCC = () => {
		switch (isOpenRequest) {
			case true: {
				setErrorMessage('');
				clearErrors();
				dispatch(changeNumOfPaymentsAC(watch().numOfPayments));
				dispatch(setOpenReqDataAC(payerDetails));
				if (!isFetching) {
					dispatch(changePaymentOption('card', payerDetails, true));
				}

				setCardMethodChozen(true);
				break;
			}

			default: {
				dispatch(changeNumOfPaymentsAC(watch().numOfPayments));
				if (!isFetching) {
					dispatch(changePaymentOption('card'));
				}

				setCardMethodChozen(true);
			}
		}
	};

	const showErrorPopupFunc = (currentNumberOfPayment, numberOfPayments, paymentMethod, callback = () => null) => {
		if (numberOfPayments <= 1) {
			callback();
			return;
		}

		if (paymentMethod === 'bit' && currentNumberOfPayment > 1) {
			setShowModal(true);
			return;
		}

		callback();
	};

	const mapIdToMethod = {
		1: {
			name: 'bit',
			img: bit,
			onClick: () => showErrorPopupFunc(watch().numOfPayments, numOfPayments, 'bit', setPaymentMethod('bit')),
		},
		2: {
			name: 'card',
			img: paymentsMenu,
			onClick: () => showErrorPopupFunc(watch().numOfPayments, numOfPayments, 'card', setPaymentMethod('card')),
		},
	};

	if (deleted) {
		return <div className='mt-5 alert alert-success'>בקשת התשלום בוטלה בהצלחה!</div>;
	}

	// NOTE: Redirected to /success only after succcessfull payment with CC.
	return confirmationNumber ? <Redirect to='/success'/> : (
		<div className='home'>
			{
				errorsHome.length > 0
					&& <Error>
						{errorsHome.map((element, ind) => (
							<div key={ind}>
								{element.msg}
							</div>
						))}
					</Error>
			}
			{errorMessage && <Error> {errorMessage} </Error>}
			{cardMethodChozen
				&& <iframe className='pci-iframe' title='cardPayment' src={`${WEB_PCI_BASE_URL}?ptoken=${Ptoken}`}/>}
			{paymentAmount && !cardMethodChozen && errorsHome.length <= 0
				&& <><div className='home__top'>
					<div className='home__top__title'>
						<strong>שלום{payerName && payerName !== 'undefined' ? ` ${payerName}` : ''}{payerLastName && payerLastName !== 'undefined' ? ` ${payerLastName}` : ''},</strong>
						<div>קיבלת בקשת תשלום</div>
					</div>
					<hr className='d-md-none'/>
					<div className='home__top__text'>
						{sender && (<div>מ: {sender}</div>)}
						<div className='home__top__descr'>עבור: {paymentFor} </div>
						{isOpenSum
							? <div className='d-flex align-items-center open_sum'>
								<div className='ms-3'>סכום לבחירתך: </div>
								<Field
									required
									name='sum'
									type='tel'
									label='סכום'
									value={watch().sum}
									setValue={setValue}
									errors={errors}
									trigger={trigger}
									setErrMsg={setErrorMessage}
								/>
							</div>
							: <div>סכום: {numberWithCommas(paymentAmount)} ש״ח</div>}
					</div>
				</div>
				<hr/>
				<div className='home__bottom'>
					<div className='home__bottom__text'>
						<div>לביצוע התשלום עליך לבחור את אופן התשלום.</div>
					</div>
					{isOpenContact && <>
						<div className='row'>
							<div className='col-6 col-lg-3 mt-5 mb-3 name'>
								<Field
									required
									name='name'
									label='שם פרטי'
									value={watch().name}
									setValue={setValue}
									errors={errors}
									trigger={trigger}
									setErrMsg={setErrorMessage}
								/>
							</div>
							<div className='col-6 col-lg-3 mt-5 mb-3 lastName'>
								<Field
									name='lastName'
									label='שם משפחה'
									value={watch().lastName}
									setValue={setValue}
									errors={errors}
									trigger={trigger}
									required={false}
									setErrMsg={setErrorMessage}
								/>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-8 col-lg-4 phone'>
								<Field
									required
									isPhone
									name='phone'
									type='tel'
									value={watch().phone}
									label='מספר נייד'
									setValue={setValue}
									errors={errors}
									trigger={trigger}
									maxLength={7}
								/>
							</div>
							<div className='col-4 col-lg-2 prefix'>
								<Field
									required
									name='prefix'
									value={watch().prefix}
									maxLength={7}
									setValue={setValue}
									type='select'
									errors={errors}
									options={['', ...prefixes]}
									trigger={trigger}
								/>
							</div>
						</div>
						<div className='row mt-3'>
							<div className='col-8 col-lg-6 mt-3 mb-3 email'>
								<Field
									name='email'
									type='text'
									value={watch().email}
									label='כתובת דוא״ל'
									required={false}
									setValue={setValue}
									errors={errors}
									trigger={trigger}
								/>
							</div>
						</div>
					</>}
					<div className='home__bottom__images d-flex'>
						{paymentMethods && paymentMethods.map(({Value, Decscription}) => (
							<PaymentButton
								key={Value}
								id={Value}
								title={Decscription}
								item={mapIdToMethod[Value]}
								isFetching={mapIdToMethod[Value].name === 'bit' && isFetching}
								activeMethod={paymentMethod}/>
						))}
					</div>
					{((numOfPayments && numOfPayments > 1) || (isOpenSum && !numOfPayments)) && <div className='d-flex align-items-center mt-3'>
						<div id='numOfPaymentsText' className='ms-3'>מספר תשלומים: </div>
						<Field
							required
							name='numOfPayments'
							value={watch().numOfPayments}
							setValue={setValue}
							numOfPayments={numOfPayments}
							paymentMethod={paymentMethod}
							showErrorPopupFunc={showErrorPopupFunc}
							type='select'
							errors={errors}
							options={numOfPayments ? fillSelectOptions(numOfPayments) : fillSelectOptions(12)}
							trigger={trigger}
						/>
					</div>}
					<div className='d-flex flex-md-column flex-column-reverse mt-3'>
						<div className='home__bottom__text'>
							<div>
								במידה וקיבלת את הבקשה בטעות עליך ללחוץ על &quot;ביטול&quot;.
								<br/>
								התשלום מבוצע בהתאם לתקן אבטחה מחמיר PCI.
							</div>
						</div>
						<hr className='d-md-none'/>
						<div className='home__bottom__cancel mt-4'>
							<button className='btn btn-link' type='button' onClick={_cancelRequest}>ביטול</button>
							<Button disabled={paymentMethod === null || !isValid} className='yellow_button d-flex align-items-center' onClick={() => showErrorPopupFunc(watch().numOfPayments, numOfPayments, paymentMethod, () => nextFunc(paymentMethod))}>
								<div>
										לתשלום
								</div>
								<div className='d-flex arrow'>
									<img src={chevron} alt='<'/>
								</div>
							</Button>
						</div>
					</div>
				</div>
				</>}
			<Popup showModal={showModal} setShowModal={setShowModal} confirmFunc={() => [setShowModal(false), setValue('numOfPayments', 1)]}/>
		</div>
	);
}

export default Home;
