import React from 'react';
import selected_ok from '../../images/selected_ok.png';
import Loader from './loader/Loader';

export default ({
	isFetching,
	activeMethod = '',
	title = '',
	item,
}) => {
	const isActive = () => activeMethod === item.name;

	return item ? (
		<div className='home__bottom__images__images-wrapper'>
			{isFetching && <div className='d-flex .border-black home__bottom__images__images-wrapper__loader justify-content-center'>
				<div className='spinner-border' role='status'>
					<span className='visually-hidden'>Loading...</span>
				</div>
			</div>}
			{isActive() && <div className='home-selected-ok'>
				<img src={selected_ok} alt=''/>
			</div>}
			<div className={isActive() ? 'home__bottom__images__images-wrapper__image active' : 'home__bottom__images__images-wrapper__image'} onClick={item.onClick}>
				<img src={item.img} alt=''/>
			</div>
			<div style={isActive() ? {fontWeight: 'bold'} : null}>
				{title}
			</div>
		</div>
	) : null;
};
