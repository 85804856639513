import thunkMiddleware from 'redux-thunk';
import paymentDetailsReducer from './payment-details-reducer.js';

const {createStore, combineReducers, applyMiddleware} = require('redux');

const reducers = combineReducers({
	paymentDetails: paymentDetailsReducer,
});

window.store = createStore(reducers, applyMiddleware(thunkMiddleware));

export default window.store;
