import React from 'react';
import logo from '../../../images/logo_fnx.svg';

/**
 *
 * @param root0
 * @param root0.children
 */
function Layout({children}) {
	return (
		<div className='layout'>
			<div className='layout__inner'>
				<div className='layout__inner__top'>
					<img src={logo} alt=''/>
				</div>
				<div className='layout-inner-bottom'>
					{children}
				</div>
			</div>
		</div>
	);
}

export default Layout;
