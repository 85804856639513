import React from 'react';
import './Loader.css';

/**
 *
 * @param root0
 * @param root0.wrapperStyle
 * @param root0.loaderStyle
 */
function Loader({wrapperStyle, loaderStyle}) {
	return (
		<div className='loader' style={loaderStyle}>
			<div className='rect1'/>
			<div className='rect2'/>
			<div className='rect3'/>
			<div className='rect4'/>
			<div className='rect5'/>
		</div>
	);
}

export default Loader;
