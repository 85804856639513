import React from 'react';
import {Modal, Button} from 'react-bootstrap';

/**
 *
 * @param root0
 * @param root0.showModal
 * @param root0.setShowModal
 * @param root0.confirmFunc
 */
function Popup({showModal, setShowModal, confirmFunc}) {
	return (
		<Modal
			centered
			show={showModal}
			size='lg'
			aria-labelledby='contained-modal-title-vcenter'
			className='popup'
			onHide={() => setShowModal(false)}
		>
			<Modal.Header closeButton>
				<div id='contained-modal-title-vcenter' className='popup_title'>
					התשלום לא בוצע
				</div>
			</Modal.Header>
			<Modal.Body>
				<div>לא ניתן לבצע עסקת תשלומים באפליקציית ביט.</div>
			</Modal.Body>
			<Modal.Footer>
				<Button className='popup_button' onClick={() => confirmFunc()}>אישור</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default Popup;
