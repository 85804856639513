import {init} from '@elastic/apm-rum';

const {REACT_APP_APM_SERVER, REACT_APP_APM_ENV} = process.env;

if (REACT_APP_APM_SERVER) {
	init({
		serviceName: 'gpw',
		serverUrl: REACT_APP_APM_SERVER,
		environment: REACT_APP_APM_ENV,
		distributedTracing: true,
		// Allow all localhost origins
		distributedTracingOrigins: [new RegExp('http://localhost:.+')],
	});

	console.debug('APM initialized', {REACT_APP_APM_SERVER, REACT_APP_APM_ENV});
}
