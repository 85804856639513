/* eslint-disable react/boolean-prop-naming */
import React, {useEffect, useRef} from 'react';
import {FormGroup, Input, Label} from 'reactstrap';
import PropTypes from 'prop-types';
import numberWithCommas from '../../utils/numberWithCommas.js';

/**
 *
 * @param {object} root0 the parameters of the field
 * @param {string} root0.name name of the field
 * @param {string} root0.label label of the field
 * @param {string} root0.type type of the field
 * @param {Array} root0.options options of the field
 * @param {string} root0.value value of the field
 * @param {Function} root0.setValue set value of the field
 * @param {Array} root0.errors errors of the field
 * @param {boolean} root0.required required
 * @param {Function} root0.trigger trigger
 * @param {number} root0.maxLength max length of the field
 * @returns {React.Component} component of the field
 */
function Field({
	name,
	label,
	type = 'text',
	options = null,
	value,
	setValue,
	errors,
	required = false,
	trigger,
	maxLength = undefined,
	...properties
}) {
	const inputReference = useRef();
	const inputWrapperReference = useRef();

	useEffect(() => {
		if (!value) {
			return;
		}

		handleClick();
	}, []);

	const handleClick = () => {
		const _input = inputWrapperReference.current.querySelector('input');
		const _label = inputWrapperReference.current.querySelector('label');

		_label.classList.add('myanimatelabel');
		if (type === 'select') {
			return;
		}

		_input.focus();
	};

	const handleBlur = event => {
		const _input = event.target;
		const _label = _input.nextElementSibling;

		if (_input.value === '') {
			_label.classList.remove('myanimatelabel');
		} else {
			_label.classList.add('myanimatelabel');
		}
	};

	const validateValue = (inputName, inputValue) => {
		switch (inputName) {
			case 'phone': {
				return inputValue.replace(/\D/, '');
			}

			case 'sum': {
				const inputWithoutCommas = inputValue.replaceAll(',', '');
				const tested = /^((0|[1-9]\d{0,7})(\.(\d{1,2})?)?)?$/.test(inputWithoutCommas);
				properties.setErrMsg('');
				if (Number(inputWithoutCommas) > 10_000_000 && inputWithoutCommas.length !== 9) {
					properties.setErrMsg('לא ניתן להזין סכום גבוה מ- 10,000,000');
				}

				return tested ? numberWithCommas(inputWithoutCommas) : value;
			}

			default: {
				return inputValue;
			}
		}
	};

	return (
		<FormGroup key={name} className='field-wrapper'>
			<div ref={inputWrapperReference} className='position-relative additionsl' onClick={handleClick}>
				<Input
					ref={inputReference}
					name={name}
					id={name}
					type={type}
					invalid={Boolean(errors[name])}
					options={options}
					autoComplete='off'
					className={`input-field input-field-${name}`}
					maxLength={maxLength}
					onChange={onChange}
					onBlur={event => handleBlur(event)}
				>
					{options ? options.map(key => (
						<option key={key} value={key}>{'\u200F' + key}</option>
					)) : null}
				</Input>
				<Label for={name}>
					{label} {(required && type !== 'select') && <span className='text-danger'>*</span>}
				</Label>
			</div>
			{(errors[name] && errors[name].message) && <div className='text-danger small mt-1'>{errors[name].message}</div>}
		</FormGroup>
	);

	/**
	 *
	 * @param {object} event event object
	 */
	function onChange(event) {
		const {value} = event.target;
		if (name === 'numOfPayments') {
			properties.showErrorPopupFunc(value, properties.numOfPayments, properties.paymentMethod, setValue(name, validateValue(name, value)));
		} else {
			const formattedValue = validateValue(name, value);
			event.target.value = formattedValue;
			setValue(name, formattedValue);
		}

		setTimeout(() => trigger(name), 50);
	}
}

Field.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	options: PropTypes.array,
	value: PropTypes.string,
	setValue: PropTypes.func,
	errors: PropTypes.object,
	required: PropTypes.bool,
	trigger: PropTypes.func,
	maxLength: PropTypes.number,
	setErrMsg: PropTypes.func,
	showErrorPopupFunc: PropTypes.func,
	numOfPayments: PropTypes.number,
	paymentMethod: PropTypes.string,
};

export default Field;
