import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchDataWithToken} from '../../redux/payment-details-reducer.js';
import Error from '../components/error/error.jsx';
import numberWithCommas from '../../utils/numberWithCommas.js';
import splitPhone from '../../utils/splitPhone.js';
import Loader from '../components/loader/Loader';

/**
 *
 */
function ConfirmationScreen() {
	const {payerName, payerLastName, payerPhone, paymentAmount, sender, confirmationNumber, date, errorsConf, paymentOption, paymentFor, payerNameOpen, payerLastNameOpen, paymentAmountOpen, payerPhoneOpen, isOpenSum, isOpenContact, selectNumOfPayments,
		payerEmailOpen, payerEmail} = useSelector(({paymentDetails}) => paymentDetails);
	const dispatch = useDispatch();

	useEffect(() => {
		if (paymentOption !== 'card') {
			dispatch(fetchDataWithToken());
		}
	}, [null]);

	if (errorsConf.length > 0) {
		return (
			<Error>
				{errorsConf.map((element, ind) => (
					<div key={ind}>
						{element.msg}
					</div>
				))}
			</Error>
		);
	}

	return (confirmationNumber) ? (
		<div className='confirmation-screen'>
			<div className='confirmation-screen__success'>
				<div className='confirmation-screen__success__subtitle'>
					אישור תשלום - החיוב בוצע בהצלחה!
				</div>
				<div className='confirmation-screen__success__text'>
					מספר אסמכתא: <span> {confirmationNumber}</span>
				</div>
			</div>
			<hr/>
			<div className='confirmation-screen__details'>
				<div className='confirmation-screen__details__title'>
					פרטי העסקה
				</div>
				<div className='confirmation-screen__details__subtitle'>
					בוצע תשלום בסך {numberWithCommas(isOpenSum ? paymentAmountOpen : paymentAmount)} ש״ח {(selectNumOfPayments && selectNumOfPayments === '1') ? 'בתשלום 1' : `ב-${selectNumOfPayments} תשלומים`}.
				</div>
				<div className='confirmation-screen__details__text'>
					<strong>תאריך:</strong> {date}
				</div>
				<div className='confirmation-screen__details__text'>
					<strong>שם העסק:</strong> {sender}
				</div>
				<div className='confirmation-screen__details__text'>
					<strong>עבור:</strong> {paymentFor}
				</div>
			</div>
			<hr className='d-md-none'/>
			<div className='confirmation-screen__details'>
				<div style={{marginTop: '38px'}} className='confirmation-screen__details__title'>
					פרטי הלקוח
				</div>
				<div className='confirmation-screen__details__text'>
					<strong>שם:</strong> {isOpenContact ? payerNameOpen + ' ' + (payerLastNameOpen ? payerLastNameOpen : '') : payerName + ' ' + (payerLastName ? payerLastName : '')}
				</div>
				<div className='confirmation-screen__details__text'>
					<strong>טלפון:</strong> {isOpenContact ? splitPhone(payerPhoneOpen) : splitPhone(payerPhone)}
				</div>
				<div className='confirmation-screen__details__text'>
					<strong>דוא״ל:</strong> {isOpenContact ? payerEmailOpen : payerEmail}
				</div>
			</div>

			<div className='confirmation-screen__invoice-send'>
				לידיעתך, חשבונית תשלח אליך במידה והינך מנוי לשירות זה
			</div>
		</div>
	) : (
		<Loader/>
	);
}

export default ConfirmationScreen;
