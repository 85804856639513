export default (name, url = window.location.href) => {
	name = name.replaceAll(/[[\]]/g, '\\$&');
	const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(url);
	if (!results) {
		return null;
	}

	if (!results[2]) {
		return '';
	}

	return decodeURIComponent(results[2].replaceAll('+', ' '));
};

export const hasQueryParam = (name, url = window.location.href) => url.includes(name);
