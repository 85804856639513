import './utils/tracer.js'; // Do not remove this line, it is used to initialize the tracer before any other code is run.
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './jsx/App.js';
import store from './redux/redux-store.js';
import reportWebVitals from './reportWebVitals.js';
import {BrowserRouter} from 'react-router-dom';

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<App/>
		</Provider>
	</BrowserRouter>,
	document.querySelector('#root'),
);

reportWebVitals();
