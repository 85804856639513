import axios from 'axios';
import isMobileDevice from '../utils/isMobileDevice.js';
import getQueryParam, {hasQueryParam} from '../utils/getQueryParam.js';
import getTransactionSourceId from '../utils/getTransactionSourceId.js';

const {
	REACT_APP_API_ENDPOINT = window.location.origin + '/api',
} = process.env;

const CHANGE_PAYMENT_OPTION = 'CHANGE-PAYMENT-OPTION';
const CHANGE_SESSION_ID = 'CHANGE-SESSION-ID';
const IS_FETCHING = 'IS-FETCHING';
const SET_PAYMENT_DETAILS = 'SET-PAYMENT-DETAILS';
const SET_ERRORS = 'SET-ERRORS';
const SET_ERRORS_CONF = 'SET-ERRORS-CONF';
const SET_PAYMENT_DETAILS_CONF = 'SET-PAYMENT-DETAILS-CONF';
const SET_OPEN_REQ_DATA = 'SET_OPEN_REQ_DATA';
const CHANGE_NUM_OF_PAYMENTS = 'CHANGE_NUM_OF_PAYMENTS';
const SET_PAYER_DETAILS = 'SET_PAYER_DETAILS';

const initialState = {
	payerName: '',
	payerLastName: '',
	sender: '',
	paymentFor: '',
	paymentAmount: '',
	paymentOption: '',
	sessionId: '',
	orderId: '',
	isFetching: false,
	payerPhone: '',
	Ptoken: '',
	errorsHome: [],
	errorsConf: [],
	confirmationNumber: '',
	date: '',
	deleted: false,
	paymentMethods: [],
	numOfPayments: null,
	selectNumOfPayments: null,
	qsmId: '',
	WEB_BIT_JS_BUNDLE: '',
	WEB_PCI_BASE_URL: '',
	payreqToken: '',
	sid: '',
	isOpenSum: false,
	isOpenContact: false,
	payerNameOpen: '',
	payerLastNameOpen: '',
	payerPhoneOpen: '',
	payerEmailOpen: '',
	paymentAmountOpen: '',
};

const paymentDetailsReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_DELETED': {
			return {
				...state,
				deleted: action.payload,
			};
		}

		case CHANGE_PAYMENT_OPTION: {
			return {
				...state,
				paymentOption: action.activePaymentOption,
			};
		}

		case CHANGE_SESSION_ID: {
			return {
				...state,
				sessionId: action.sessionId,
			};
		}

		case IS_FETCHING: {
			return {
				...state,
				isFetching: action.isFetching,
			};
		}

		case SET_PAYMENT_DETAILS: {
			return {
				...state,
				payerName: action.details.payerFirstName,
				payerLastName: action.details.payerLastName,
				sender: action.details.sender,
				paymentFor: action.details.paymentFor,
				payerPhone: action.details.payerPhone,
				payerEmail: action.details.payerEmail,
				paymentAmount: action.details.sum,
				orderId: action.details.orderId,
				Ptoken: action.details.Ptoken,
				sid: action.details.sid,
				qsmId: action.details.qsmId,
				paymentMethods: action.details.paymentMethods,
				numOfPayments: action.details.numOfPayments,
				selectNumOfPayments: action.details.numOfPayments,
				WEB_PCI_BASE_URL: action.details.WEB_PCI_BASE_URL,
				WEB_BIT_JS_BUNDLE: action.details.WEB_BIT_JS_BUNDLE,
				payreqToken: action.details.payreqToken,
				isOpenSum: action.details.isOpenSum,
				isOpenContact: action.details.isOpenContact,
			};
		}

		case SET_ERRORS: {
			return {
				...state,
				errorsHome: action.errorsHome,
			};
		}

		case SET_ERRORS_CONF: {
			return {
				...state,
				errorsConf: action.errorsConf,
			};
		}

		case SET_PAYMENT_DETAILS_CONF: {
			return {
				...state,
				confirmationNumber: action.detailsConf.transactionId,
				date: action.detailsConf.date,
			};
		}

		case SET_OPEN_REQ_DATA: {
			return {
				...state,
				payerNameOpen: action.payerData.payerName,
				payerLastNameOpen: action.payerData.lastName,
				payerPhoneOpen: action.payerData.phonePrefix ? `${action.payerData.phonePrefix}${action.payerData.phone}` : action.payerData.phone,
				payerEmailOpen: action.payerData.email,
				paymentAmountOpen: action.payerData.paymentAmount,
			};
		}

		case CHANGE_NUM_OF_PAYMENTS: {
			return {
				...state,
				selectNumOfPayments: action.numOfPayments,
			};
		}

		case SET_PAYER_DETAILS: {
			return {
				...state,
				payerName: action.payerDetails.paymentResponse.payerName,
				payerNameOpen: action.payerDetails.paymentResponse.payerName,
				payerPhone: action.payerDetails.paymentResponse.payerPhoneNumber,
				payerPhoneOpen: action.payerDetails.paymentResponse.payerPhoneNumber,
				payerEmail: action.payerDetails.paymentResponse.payerMail,
				payerEmailOpen: action.payerDetails.paymentResponse.payerMail,
				paymentAmount: action.payerDetails.paymentResponse.bit.singlePaymentStatus.data.requestAmount,
				paymentAmountOpen: action.payerDetails.paymentResponse.bit.singlePaymentStatus.data.requestAmount,
				selectNumOfPayments: 1,
			};
		}

		default: {
			return state;
		}
	}
};

const setPaymentDetailsAC = details => ({type: SET_PAYMENT_DETAILS, details});
const setPaymentDetailsConfAC = detailsConf => ({type: SET_PAYMENT_DETAILS_CONF, detailsConf});
const changePaymentOptionAC = activePaymentOption => ({type: CHANGE_PAYMENT_OPTION, activePaymentOption});
const changeSessionIdAC = sessionId => ({type: CHANGE_SESSION_ID, sessionId});
export const isFetchingAC = isFetching => ({type: IS_FETCHING, isFetching});
const setErrorsAC = errorsHome => ({type: SET_ERRORS, errorsHome});
export const setErrorsConfAC = errorsConf => ({type: SET_ERRORS_CONF, errorsConf});
export const setOpenReqDataAC = payerData => ({type: SET_OPEN_REQ_DATA, payerData});
export const changeNumOfPaymentsAC = numberOfPayments => ({type: CHANGE_NUM_OF_PAYMENTS, numOfPayments: numberOfPayments});
const setPayerDetails = payerDetails => ({type: SET_PAYER_DETAILS, payerDetails});

export const changePaymentOption = (activePaymentOption, paymentDetails) => async (dispatch, getState) => {
	const {paymentDetails: {payreqToken, sid}} = getState();

	dispatch(changePaymentOptionAC(activePaymentOption));

	const bitCond = activePaymentOption === 'bit';

	bitCond && dispatch(isFetchingAC(true));
	bitCond && await axios({
		method: 'post',
		url: `${REACT_APP_API_ENDPOINT}/payreq/bit/session`,
		data: {
			sum: paymentDetails.paymentAmount,
			name: paymentDetails.payerName,
			lastName: paymentDetails.lastName,
			phonePrefix: paymentDetails.phonePrefix,
			phone: paymentDetails.phone,
			email: paymentDetails.email,
			orderId: paymentDetails.orderId,
			sendSMS: !isMobileDevice(),
			token: payreqToken,
		},
		headers: {
			sid,
		},
		validateStatus: status => status && status < 500,
	}).then(({data}) => {
		console.debug('changePaymentOption', data);
		if (data && data.errors && data.errors.length > 0) {
			return dispatch(setErrorsAC(data.errors));
		}

		dispatch(changeSessionIdAC(data.data.session));
	});
	bitCond && dispatch(isFetchingAC(false));
};

export const fetchPayerData = () => async dispatch => {
	const orderId = getQueryParam('id');
	const sid = getQueryParam('sid') || '';

	return axios({
		method: 'get',
		url: `${REACT_APP_API_ENDPOINT}/payreq/${orderId ? encodeURIComponent(orderId) : '0'}`,
		validateStatus: status => status && status < 500,
	}).then(({data}) => {
		console.debug('fetchPayerData', data);
		if (data && data.data) {
			dispatch(setPaymentDetailsAC(Object.assign(data.data, {orderId, sid})));
		}

		if (data && data.errors && data.errors.length > 0) {
			dispatch(setErrorsAC(data.errors));
		}

		return data;
	});
};

export const fetchDataWithToken = () => async (dispatch, getState) => {
	const token = await getToken();
	console.debug('fetchDataWithToken', token);

	const {paymentDetails: {qsmId = '', sid, payerName, payerNameOpen}} = getState();

	await axios({
		method: 'post',
		url: `${REACT_APP_API_ENDPOINT}/payreq/bit/confirm`,
		data: {token, qsmId},
		headers: {
			sid,
		},
	}).catch(error => {
		console.error(error);
		dispatch(setErrorsConfAC([{msg: 'התשלום הסתיים בהצלחה, אבל קרתה שגיאה במהלך הצגת דף זה.'}]));
	})
		.then(data => {
			data.data && dispatch(setPaymentDetailsConfAC(data.data.data));
			if (!payerName && !payerNameOpen) {
				dispatch(setPayerDetails(data.data.data));
			}
		});
};

export const fetchConfirmData = async encryptedGpwToken => await axios({
	method: 'get',
	url: `${REACT_APP_API_ENDPOINT}/payreq/bit/confirm`,
	headers: {encryptedGpwToken},
})
	.then(({data}) => data.data);

export const ccCreateTransaction = pciData => (dispatch, getState) => {
	const {success, data} = pciData;
	if (success) {
		const {payerID, PTXACTION} = data;
		const orderId = getQueryParam('id');
		const {paymentDetails: {payreqToken, sid, payerNameOpen, payerLastNameOpen, payerPhoneOpen, paymentAmountOpen, selectNumOfPayments, payerEmailOpen}} = getState();

		const transactionSourceId = getTransactionSourceId();
		axios({
			method: 'post',
			url: `${REACT_APP_API_ENDPOINT}/payreq/cc/transaction`,
			data: {
				payerID,
				PTXACTION,
				orderId,
				token: payreqToken,
				sum: paymentAmountOpen,
				payerName: payerNameOpen,
				payerLastName: payerLastNameOpen,
				payerPhone: payerPhoneOpen,
				payerEmail: payerEmailOpen,
				numOfPayments: selectNumOfPayments,
				transactionSourceId,
			},
			headers: {
				sid,
			},
			validateStatus: status => status && status < 500,
		})
			.then(({data}) => {
				if (data && data.data) {
					const {GamaPayDealId: transactionId, DealDate: date} = data.data;

					dispatch(setPaymentDetailsConfAC({
						transactionId,
						date,
					}));
				}

				if (data && data.errors && data.errors.length > 0) {
					dispatch(setErrorsAC(data.errors));
				}
			})
			.catch(error => dispatch(setErrorsConfAC(error.response.data.errors)));
	}
};

export const payReqCancel = () => (dispatch, getState) => {
	const id = getQueryParam('id');
	const {paymentDetails: {payreqToken, sid}} = getState();
	dispatch(isFetchingAC(true));
	axios({
		method: 'delete',
		url: `${REACT_APP_API_ENDPOINT}/payreq/${id}`,
		data: {token: payreqToken},
		headers: {
			sid,
		},
		validateStatus: status => status && status < 500,
	}).then(({data}) => data).then(({data, success, errors}) => {
		dispatch(isFetchingAC(false));
		if (success) {
			dispatch({type: 'SET_DELETED', payload: true});
		}

		if (errors && errors.length > 0) {
			dispatch(setErrorsAC(errors));
		}
	}).catch(error => dispatch(setErrorsConfAC(error.response.data.errors)));
};

/**
 *
 */
async function getToken() {
	if (hasQueryParam('confirm')) {
		return getQueryParam('confirm');
	}

	if (!hasQueryParam('encryptedGpwToken')) {
		return;
	}

	const encryptedGpwToken = getQueryParam('encryptedGpwToken');
	return await fetchConfirmData(encryptedGpwToken);
}

export default paymentDetailsReducer;
